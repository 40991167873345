import configureStore from '../store/store';

const state = configureStore().getState();

const isModuleActive = (module) => {
    if (!state.group) { return false; }
    if (!state.group.modules) { return false; }
    if (!state.group.modules[module]) { return false; }
    if (!state.group.modules[module].active) { return false; }

    return true;
}

export const UtilGroup = {
    isModuleActive
};