import React from 'react';
import TableRowLoader from './TableRowLoader';
import TableRowNoResult from './TableRowNoResult';

class TableBody extends React.Component {
    render() {
        return (
            <tbody id={this.props.id} className={this.props.className} style={this.props.style}>
                <TableRowLoader loading={this.props.loading} colSpan={this.props.cols} />
                <TableRowNoResult items={this.props.children} loading={this.props.loading} colSpan={this.props.cols} />
                {!this.props.loading && this.props.children}
            </tbody>
        );
    }
}

TableBody.defaultProps = {
    id: 'table-body',
    className: '',
    style: {}
}

export default TableBody;