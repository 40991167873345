import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { t } from 'i18next';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';

class ModalGroupSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: true
        }
    }

    render() {
        const { open } = this.state;
        const { group } = this.props;

        if (Object.keys(group).length > 0) {
            return null;
        }

        return (
            <Modal isOpen={open}>
                <ModalHeader>{t('modal.group:title')}</ModalHeader>
                <ModalBody>
                    {t('modal.group:text')}<br />

                    {
                        Object.entries(this.props.agenda.groups).map(([groupId, item]) => {
                            return (
                                <Button color="default" key={groupId} onClick={() => this.onGroupSelect(item)}>
                                    {item.name}
                                </Button>
                            );
                        })
                    }
                </ModalBody>

                <ModalFooter>
                    <Button color="default" onClick={this.toggleModal}>{t('common:Cancel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }

    toggleModal = () => {
        this.setState({ open: !this.state.open });
    }

    onGroupSelect = (group) => {
        this.props.actions.groupUpdate(group);
        this.props.actions.agendaSet('active', group.agendas[0]);

        this.toggleModal();
    }
}

const mapStateToProps = state => ({ group: state.group });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(ModalGroupSelect);