import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";

// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import { withNamespaces } from 'react-i18next';

import configureStore from './store/store';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastContainer } from 'react-toastify';
const store = configureStore();


class App extends Component {
  componentDidMount() {
    const { client } = store.getState();

    if (!client) {
      return;
    }

    const { nativeLanguage } = client.logged_in;

    if (this.props.i18n.language !== nativeLanguage) {
      this.props.i18n.changeLanguage(nativeLanguage);
    }
  }

  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
      <BrowserRouter basename={basename}>
        <LastLocationProvider>
          <ToastContainer pauseOnFocusLoss={false} autoClose={2000} />
          <Routes />
        </LastLocationProvider>
      </BrowserRouter>
    );

  }
}

export default withNamespaces()(App);