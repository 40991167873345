import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import ToggleFullscreen from '../ToggleFullscreen';
import HeaderRun from './Header.run'
import routes from '../../routing/routes';
import { reverse } from 'named-urls';
import { ApiAgendaService } from '../../services/api/agenda.service';
import { toast } from 'react-toastify';
import DropdownCommunicationCenter from '../dropdown/DropdownCommunicationCenter';
import { UtilUrl } from '../../utils/url.util';

class Header extends Component {

    componentDidMount() {
        HeaderRun();

        this.closeOffsidebarOnClick();
        setInterval(this.watchNotificationChanges, 60 * 5 * 1000);
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsedText');
        this.props.actions.changeSetting('wasCollapsedBeforeSettings', false);
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    onSearchSubmit = (e) => {
        e.preventDefault();

        const inputEl = document.getElementById('navbar-form-search-field');
        let value = inputEl.value;

        inputEl.value = '';

        this.props.history.push(reverse(routes.agenda.appointments.search, { searchStr: value }));
    }

    watchNotificationChanges = () => {
        const { notifications } = this.props;

        ApiAgendaService.getNotifications()
            .then(result => {
                this.props.actions.notificationsLoaded(result);

                Object.entries(result).forEach(([key, value]) => {
                    if (notifications[key] === undefined) {
                        return;
                    }

                    if (value <= notifications[key]) {
                        return;
                    }

                    let message = null;
                    let diff = value - notifications[key];
                    switch (key) {
                        case 'pendingTasks':
                            message = t('tools.task:pendingTasksNew', { count: diff });
                            break;
                        case 'reminders':
                            message = t('tools.reminder:remindersNew', { count: diff });
                            break;
                        case 'unreadMessages':
                            message = t('profile.mailbox:newMessage', { count: diff });
                            break;
                        default:
                            message = null;
                            break;
                    }

                    if (!message) {
                        return;
                    }

                    toast(message, { type: 'info', position: 'bottom-right' });
                });
            })
    }

    closeOffsidebarOnClick = () => {
        document.addEventListener('click', (e) => {
            if (e.path) {
                const pathIds = e.path.map((pathEl) => {
                    return pathEl.id;
                });

                if (pathIds.includes('nav-item-offsidebar-settings') || pathIds.includes('offsidebar-settings')) {
                    return;
                }

                this.props.actions.changeSetting('offsidebarOpen', false);
            }
        });
    }

    handleModuleLinkClick = (module) => {
        this.props.actions.changeSetting('activeModule', module);

        if (module === 'agenda') {
            this.props.history.push(UtilUrl.generate(routes.agenda.myagenda, { agenda: this.props.agenda.active }));
        } else if (module === 'shop') {
            this.props.history.push(UtilUrl.generate(routes.shop.orders.overview));
        }
    }

    render() {
        const { activeModule } = this.props.settings;
        const { group } = this.props;

        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/reservi-logo.svg" alt="Reservi logo" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="images/reservi-logo-collapsed.svg" alt="Reservi logo" />
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>

                        {group && group.modules && Object.entries(group.modules).map(([module, data], i) => {
                            if (!data.active) { return null; }

                            return (
                                <li key={i} className={'nav-item ' + (activeModule === module ? 'active' : '')}>
                                    <a className="nav-link" href="#" onClick={(e) => { e.preventDefault(); this.handleModuleLinkClick(module) }}>
                                        {t('common:modules:' + module)}
                                    </a>
                                </li>
                            )
                        })}

                        { /* Fullscreen (only desktops) */}
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullscreen className="nav-link" />
                        </li>


                        { /* Search icon */}
                        <li className="nav-item">
                            <a className="nav-link" href="" data-search-open="">
                                <em className="icon-magnifier"></em>
                            </a>
                        </li>

                        { /* START lock screen 
                        <li className="nav-item d-none d-md-block">
                            <Link to="lock" title="Lock screen" className="nav-link">
                                <em className="icon-lock"></em>
                            </Link>
                        </li>*/}
                        { /* END lock screen */}
                    </ul>
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        { /* START stats button */}
                        <li className="nav-item d-md-block">
                            <Link className="nav-link" to={{ pathname: UtilUrl.generate(routes.agenda.appointments.statistics.overview) }}>
                                <em className="icon-graph"></em>
                            </Link>
                        </li>
                        { /* END stats button */}

                        { /* START Alert menu */}
                        {/*UtilClient.isLoggedIn() && <DropdownUserNotifications notifications={this.props.notifications} />*/}
                        { /* END Alert menu */}

                        { /* START Communication center menu */}
                        <DropdownCommunicationCenter notifications={this.props.notifications} />
                        { /* END Communication center menu */}

                        {/* START Mailbox */}
                        {/*<li className="nav-item d-none d-md-block">
                            <Link to={{ pathname: reverse(routes.agenda.mailbox.inbox) }} className="nav-link">
                                <i className="icon-envelope"></i>
                            </Link>
                         </li>*/}
                        {/* END Mailbox */}

                        { /* START User avatar toggle */}
                        {/*<li className="nav-item d-none d-md-block">
                            <a className="nav-link" onClick={this.toggleUserblock}>
                                <em className="icon-user"></em>
                            </a>
                        </li>*/}
                        { /* END User avatar toggle */}

                        { /* START Offsidebar button */}
                        <li className="nav-item d-md-block">
                            <Link className="nav-link" to={{ pathname: UtilUrl.generate(routes.agenda.profiles.overview) }}>
                                <em className="icon-settings"></em>
                            </Link>
                        </li>
                        {/*<li className="nav-item" id="nav-item-offsidebar-settings">
                            <a className="nav-link" href="" onClick={this.toggleOffsidebar}>
                                <em className="icon-settings"></em>
                            </a>
                        </li>*/}
                        { /* END Offsidebar menu */}

                        { /* START logout */}
                        <li className="nav-item d-none d-md-block">
                            <Link to={{ pathname: routes.auth.login }} title="Logout" className="nav-link">
                                <em className="icon-logout"></em>
                            </Link>
                        </li>
                        { /* END logout */}
                    </ul>
                    { /* END Right Navbar */}

                    { /* START Search form */}
                    <form className="navbar-form" role="search" onSubmit={this.onSearchSubmit}>
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder={t('common:search.placeholder')} id="navbar-form-search-field" />
                            <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                        </div>
                        <button className="d-none" type="submit">Submit</button>
                    </form>
                    { /* END Search form */}
                </nav>
                { /* END Top Navbar */}
            </header >
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ agenda: state.agenda ? state.agenda.active : null, group: state.group, settings: state.settings, notifications: state.notifications });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);