import { reverse } from "named-urls";
import configureStore from '../store/store';

const state = configureStore().getState();

const generate = (route, params = {}) => {
    return reverse(route, params);
};

const isModuleActive = (location) => {
    if (!state.group) {
        return false;
    }

    if (!state.group.modules) {
        return false;
    }

    /*if (!state.settings.activeModule) {
        return false;
    }*/

    let active = true;
    Object.entries(state.group.modules).forEach(([key, data]) => {
        if (location.startsWith('/' + key) && !data.active) {
            active = false;
        }
    });

    return active;
};

export const UtilUrl = {
    generate,
    isModuleActive
};