import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

class ButtonActionToggle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: props.active
        }
    }

    render() {
        const { i, textInactive, textActive } = this.props;
        const { active } = this.state;

        return (
            <React.Fragment>
                <Button color={active ? 'success' : 'warning'} size="sm" id={'buttonToggle' + i} onClick={this.onToggle}>
                    <i className={'fas fa-' + (active ? 'check' : 'times')} aria-hidden></i>
                </Button>
                <UncontrolledTooltip placement="top" target={'buttonToggle' + i}>
                    {active ? (textInactive ? textInactive : t('common:De-activate')) : (textActive ? textActive : t('common:Activate'))}
                </UncontrolledTooltip>
            </React.Fragment>
        )
    }

    onToggle = () => {
        this.setState({ active: !this.state.active });

        let { item } = this.props;

        if (this.props.onClick) {
            this.props.onClick(item.id);
        }
    };
}

export default ButtonActionToggle;