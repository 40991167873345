import { API } from '../api';

const buildWeekScheduleAgenda = (start, view = 'week', agendaId = null, category = null) => {
    let data = { startDate: start, view: view }
    if (agendaId) {
        data['agenda'] = agendaId;
    }

    if (category) {
        data['categoryId'] = category;
    }

    return API.get('client/schedule/schedule/build-week-schedule', data)
        .then(
            response => {
                if (response.status !== 'success') {
                    return false;
                }

                return response.data;
            });

};

const buildWeekScheduleGroup = (start, view = 'day', groupId) => {
    let data = { startDate: start, view: view, groupId: groupId }

    return API.get('client/schedule/schedule/build-week-schedule-group', data)
        .then(
            response => {
                if (response.status !== 'success') {
                    return false;
                }

                return response.data;
            });

};

const getNotifications = () => {
    return API.get('client/client/agenda/get-notifications')
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.notifications;
            }

            return {};
        })
};

const getAgendaLinks = (groupId, agendaId) => {
    return API.get('client/client/agenda/get-links', { groupId: groupId, agendaId: agendaId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data
            }

            return {};
        })
}

const doRefreshPage = (agendaId) => {
    return API.get('client/client/agenda/refresh-agenda', { agendaId: agendaId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return false;
        });
};

export const ApiAgendaService = {
    buildWeekScheduleAgenda,
    buildWeekScheduleGroup,
    getAgendaLinks,
    getNotifications,
    doRefreshPage
};