import routes from '../routing/routes';
import { reverse } from "named-urls";

const asembleSidebar = (group) => {
    let menu = [];

    let agendaMenu = {
        name: 'Agenda',
        icon: 'icon-calendar',
        translate: 'sidebar:agenda:main',
        submenu: []
    };

    if (group && group.agendas && group.agendas.forEach((agenda) => {
        agendaMenu['submenu'].push({
            name: agenda.title,
            translate: agenda.title,
            path: reverse(routes.agenda.myagenda, { agenda: agenda.id })
        });
    }));

    agendaMenu['submenu'].push(
        {
            name: 'Groepsoverzicht',
            translate: 'sidebar:agenda:groupoverview',
            path: reverse(routes.agenda.group)

        },
        {
            name: 'Zoek vrij tijdstip',
            translate: 'sidebar:agenda:searchfreeslot',
            path: reverse(routes.agenda.agenda.searchfreeslot)
        },
        {
            name: 'Dagafspraken',
            translate: 'sidebar:agenda:dayappointments',
            path: reverse(routes.agenda.dayappointments.overview)
        },
        {
            name: 'Historiek',
            translate: 'sidebar:agenda:history',
            path: reverse(routes.agenda.agenda.history)
        },
        {
            name: 'Conflicten',
            translate: 'sidebar:agenda:conflicts',
            path: reverse(routes.agenda.agenda.conflicts),
            label: 'appointmentConflicts'
        }
    );

    menu.push(agendaMenu);

    menu.push({
        name: 'Contactpersonen',
        icon: 'icon-people',
        translate: 'sidebar:contacts:main',
        path: reverse(routes.contacts.overview)
    });

    return menu;
};

const asembleSidebarShop = (group) => {
    let menu = [];

    let orderMenu = {
        name: 'Bestellingen',
        icon: 'fa fa-shopping-cart',
        translate: 'sidebar.shop:orders:title',
        submenu: [{
            name: 'all-orders',
            translate: 'sidebar.shop:orders:all-orders',
            path: reverse(routes.shop.orders.overview)
        },
        {
            name: 'orders-per-day',
            translate: 'sidebar.shop:orders:orders-per-day',
            path: reverse(routes.shop.orders.overviewPerDay)
        },
        {
            name: 'order-totals-per-day',
            translate: 'sidebar.shop:orders:product-totals-per-day',
            path: reverse(routes.shop.orders.overviewProductTotalsPerDay)
        }]
    };
    menu.push(orderMenu);

    let productMenu = {
        name: 'Producten',
        icon: 'fa fa-bars',
        translate: 'sidebar.shop:products:title',
        submenu: [{
            name: 'Categorieën',
            translate: 'sidebar.shop:products:categories',
            path: reverse(routes.shop.products.categories.overview)
        },
        {
            name: 'Optie groepen',
            translate: 'sidebar.shop:products:option-groups',
            path: reverse(routes.shop.products.groups.overview)
        },
        {
            name: 'Producten',
            translate: 'sidebar.shop:products:overview',
            path: reverse(routes.shop.products.overview)
        },
        {
            name: 'Allergenen',
            translate: 'sidebar.shop:products:allergens',
            path: reverse(routes.shop.allergens.overview)
        }]
    };

    if (group.modules.shop.combiDealsActive) {
        productMenu.submenu.push({
            name: 'Combi deals',
            translate: 'sidebar.shop:products:combi-deals',
            path: reverse(routes.shop.combideals.overview)
        });
    }
    menu.push(productMenu);

    let discountMenu = {
        name: 'Kortingen',
        icon: 'fa fa-percentage',
        translate: 'sidebar.shop:discount:title',
        submenu: [
            {
                name: 'overview',
                translate: 'sidebar.shop:discount:overview',
                path: reverse(routes.discounts.overview)
            }
        ]
    };
    menu.push(discountMenu);

    if (group.modules.shop.giftVouchersActive) {
        let giftVoucherMenu = {
            name: 'Cadeaubonnen',
            icon: 'fa fa-gift',
            translate: 'sidebar.shop:giftvoucher:title',
            submenu: [
                {
                    name: 'overview',
                    translate: 'sidebar.shop:giftvoucher:overview',
                    path: reverse(routes.giftvouchers.overview)
                }
            ]
        };
        menu.push(giftVoucherMenu);
    }

    let settingMenu = {
        name: 'Instellingen',
        icon: 'fa fa-cog',
        translate: 'sidebar.shop:settings:title',
        submenu: [
            {
                name: 'group',
                translate: 'sidebar.shop:settings:group',
                path: reverse(routes.shop.settings.group)
            }]
    };

    settingMenu['submenu'].push(
        {
            name: 'workload',
            translate: 'sidebar.shop:settings:workload',
            path: reverse(routes.shop.settings.workload.overview)
        },
        {
            name: 'workloadplanner',
            translate: 'sidebar.shop:settings:workloadplanner',
            path: reverse(routes.shop.settings.workloadplanner.overview)
        },
        {
            name: 'holidays',
            translate: 'sidebar:settings:holidays',
            path: reverse(routes.shop.settings.holidays.overview)
        },
    );

    if (group && group.locations.length > 0) {
        settingMenu['submenu'].push(
            {
                name: 'location',
                translate: 'sidebar.shop:settings:location',
                path: reverse(routes.shop.settings.location)
            }
        );
    }

    settingMenu['submenu'].push(
        {
            name: 'links',
            translate: 'sidebar:personalsettings:links',
            path: routes.shop.settings.links
        }
    );
    menu.push(settingMenu);

    return menu;
}

export const UtilSidebar = {
    asembleSidebar,
    asembleSidebarShop
};