import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const ButtonActionActivate = props => (
    <React.Fragment>
        <Button color={props.active ? 'success' : 'warning'} size="sm" id={'buttonActivate' + props.i} onClick={props.onClick}>
            <i className={'fas fa-' + (props.active ? 'check' : 'times')} aria-hidden></i>
        </Button>
        <UncontrolledTooltip placement="top" target={'buttonActivate' + props.i}>
            {props.active ? t('common:De-activate') : t('common:Activate')}
        </UncontrolledTooltip>
    </React.Fragment>
);

export default ButtonActionActivate;