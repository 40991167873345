const updateSmallCalendarDate = (date) => {
    var event;
    if (typeof window.CustomEvent === 'function') {
        event = new CustomEvent('dateChanged', { detail: { date: new Date(date) } });
    } else {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent('dateChanged', true, false, { date: new Date(date) });
    }

    document.dispatchEvent(event);
}

const updateSmallCalendar = (type, id = null) => {
    var event;
    if (typeof window.CustomEvent === 'function') {
        event = new CustomEvent('caseChanged', { detail: { type: type, id: id } });
    } else {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent('caseChanged', true, false, { type: type, id: id });
    }

    document.dispatchEvent(event);
}

const trigger = (event, data = null) => {
    let e = null;
    if (typeof window.CustomEvent === 'function') {
        e = new CustomEvent(event, { detail: data });
    } else {
        e = document.createEvent('CustomEvent');
        e.initCustomEvent(event, true, false, data);
    }

    document.dispatchEvent(e);
}

export const UtilEvent = {
    updateSmallCalendarDate,
    updateSmallCalendar,
    trigger
};