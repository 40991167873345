import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { UtilUrl } from '../../utils/url.util';
import routes from '../../routing/routes';

class DropdownCommunicationCenter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            items: [
                /*{
                    'title': t('header.communication:messages.title'),
                    'text': 'header.communication:messages.text',
                    'variable': 'unreadMessages',
                    'icon': 'envelope',
                    'link': UtilUrl.generate(routes.agenda.mailbox.inbox),
                    'color': 'warning'
                },*/
                {
                    'title': t('header.communication:mails.title'),
                    'text': t('header.communication:mails.text'),
                    'icon': 'paper-plane',
                    'link': UtilUrl.generate(routes.agenda.communication.mail.compose),
                    'color': 'success'
                },
                /*{
                    'title': t('header.communication:sms.title'),
                    'text': t('header.communication:sms.text'),
                    'icon': 'mobile-alt',
                    'link': UtilUrl.generate(routes.agenda.communication.sms.compose),
                    'color': 'secondary'
                },*/
                {
                    'title': t('header.communication:notifications.title'),
                    'text': t('header.communication:notifications.text'),
                    'icon': 'info',
                    'link': UtilUrl.generate(routes.agenda.popups.overview),
                    'color': 'primary'
                }
            ]
        }
    }

    render() {
        let unreadMessages = this.props.notifications ? this.props.notifications.unreadMessages : 0;
        let pendingTasks = this.props.notifications ? this.props.notifications.pendingTasks : 0;
        let reminders = this.props.notifications ? this.props.notifications.reminders : 0;

        const badgeCount = unreadMessages + pendingTasks + reminders;

        return (
            <UncontrolledDropdown nav inNavbar className="dropdown-list">
                <DropdownToggle nav className="dropdown-toggle-nocaret">
                    <em className="icon-bell"></em>
                    {badgeCount > 0 && <span className="badge badge-danger">{badgeCount}</span>}
                </DropdownToggle>
                { /* START Dropdown menu */}
                <DropdownMenu right className="dropdown-menu-right animated fadeIn">
                    <DropdownItem>
                        { /* START list group */}
                        <ListGroup>
                            <ListGroupItem disabled>
                                <span className="d-flex align-items-center">
                                    <span className="text-md">{t('header.communication:title')}</span>
                                </span>
                            </ListGroupItem>
                            {this.renderListItems()}
                        </ListGroup>
                        { /* END list group */}
                    </DropdownItem>
                </DropdownMenu>
                { /* END Dropdown menu */}
            </UncontrolledDropdown>
        );
    }

    renderListItems = () => {
        const { items } = this.state;

        let renderedItems = [];

        items.forEach((item, i) => {
            let count = 0;
            if (item.variable && this.props.notifications && this.props.notifications[item.variable]) {
                count = this.props.notifications[item.variable];
            }

            renderedItems.push(
                <ListGroupItem action tag={Link} to={{ pathname: item.link }} key={i}>
                    <div className="media">
                        <div className="align-self-start mr-2">
                            <em className={'fa fa-' + item.icon + ' fa-2x text-' + item.color}></em>
                        </div>
                        <div className="media-body">
                            <p className="m-0">{item.title}</p>
                            <p className="m-0 text-muted text-sm">
                                {t(item.text, { count: count })}
                            </p>
                        </div>
                    </div>
                </ListGroupItem>
            );
        });

        return renderedItems;
    }
};

export default DropdownCommunicationCenter;