import React from 'react';
import { Badge } from 'reactstrap';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const BadgeToolTip = props => (
    <React.Fragment>
        <Badge id={props.id} pill={props.pill} color={props.color}>&nbsp;</Badge>
        {props.children && (<UncontrolledTooltip placement="top" target={props.id}>{props.children}</UncontrolledTooltip>)}
    </React.Fragment >
)

export default BadgeToolTip;
