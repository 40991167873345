import * as actions from '../store/actions';
import { ApiGroupService } from '../services/api/group.service';

let loggedInClient = null;
const setClient = (client) => {
    loggedInClient = client;
};

const getLoggedInClient = () => {
    return {};
};

const isLoggedIn = () => {
    return !!loggedInClient;
};

const storeClient = (data) => {
    const { client, access_key } = data;

    actions.notificationTasksLoaded(client.notifications);
    client.notifications = undefined;

    actions.clientLoggedIn({
        logged_in: client,
        active: client,
        access_key: access_key
    });

    if (client.groups.length === 1) {
        actions.groupUpdate(client.groups[0]);
        return true;
    } else {
        return ApiGroupService.getActiveGroupClient()
            .then((result) => {
                actions.groupUpdate(result);
                return true;
            });
    }
}

export const UtilClient = {
    getLoggedInClient,
    isLoggedIn,
    storeClient,
    setClient
};