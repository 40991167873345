import { API } from '../api';

const getContact = (id) => {
    return API.get('client/contact/contact/get-contact', { id: id })
        .then(
            response => {
                if (response.status === 'success') {
                    return response.data.contact;
                }

                return [];
            }
        );

};

const getByGroup = (groupId) => {
    return API.get('client/contact/contact/get-by-group', { id: groupId })
        .then(
            response => {
                if (response.status === 'success') {
                    return response.data.contacts;
                }

                return [];
            }
        );

};

const getUnconfirmedContacts = (groupId) => {
    return API.get('client/contact/contact/get-unconfirmed-contacts', { groupId: groupId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.contacts;
            }

            throw response.data;
        });
};

const confirmDecline = (id, confirm) => {
    return API.post('client/contact/contact/confirm', { data: { id: id, confirm: confirm } })
        .then((response) => {
            if (API.isSuccess(response)) {
                return true;
            }

            throw response.data;
        });
};

const search = (data) => {
    return API.get('client/contact/contact/search', data)
        .then(
            response => {
                if (API.isSuccess(response)) {
                    return response.data.contacts;
                }

                return [];
            }
        )
};

export const ApiContactService = {
    getContact,
    getByGroup,
    getUnconfirmedContacts,
    confirmDecline,
    search
};