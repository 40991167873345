
const hexToRGB = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

const contrastPercentage = (hex) => {
    let rgb = hexToRGB(hex);
    if (rgb) {
        let r = rgb.r,
            g = rgb.g,
            b = rgb.b;
        let luminance = (r * 299 + g * 587 + b * 114) / 1000;
        return luminance / 255;
    }
    return null;
};

const contrastBlackOrWhite = (hex) => {
    return (contrastPercentage(hex) >= 0.5) ? '#000000' : '#FFFFFF';
}

const generateStyleAttributeColors = (hex) => {
    return {
        backgroundColor: hex,
        color: contrastBlackOrWhite(hex)
    };
}

export const UtilColor = {
    hexToRGB,
    contrastPercentage,
    contrastBlackOrWhite,
    generateStyleAttributeColors
};