import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/PageLoader';

import Base from './components/layout/Base';
import BasePage from './components/layout/BasePage';
import PrivateRoute from './routing/PrivateRoute';

import routes from './routing/routes';
import ErrorBoundary from './containers/Error/ErrorBoundary';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

//agenda
const MyAgenda = lazy(() => import('./containers/Agenda/MyAgenda/MyAgenda'));
const GroupOverview = lazy(() => import('./containers/Agenda/Group/GroupOverview'));
const SearchFreeSlot = lazy(() => import('./containers/Agenda/SearchFreeSlot/SearchFreeSlot'));
const History = lazy(() => import('./containers/Agenda/History/History'));
const DayAppointment = lazy(() => import('./containers/Agenda/DayAppointment/DayAppointment'));
const Conflict = lazy(() => import('./containers/Agenda/Conflict/Conflict'));
const Appointement = lazy(() => import('./containers/Agenda/Appointment/Appointment'));
const Discount = lazy(() => import('./containers/Discount/Discount'));
const GiftVoucher = lazy(() => import('./containers/GiftVoucher/GiftVoucher'));

//contacts
const Contact = lazy(() => import('./containers/Contact/Contact/Contact'));

// settings
const Profile = lazy(() => import('./containers/Settings/Profile/Profile'));
const AppointmentStatus = lazy(() => import('./containers/Settings/AppointmentStatus/AppointmentStatus'));
const Exception = lazy(() => import('./containers/Settings/Exception/Exception'));
const Holiday = lazy(() => import('./containers/Settings/Holiday/Holiday'));
const Blocking = lazy(() => import('./containers/Settings/Blocking/Blocking'));
const Category = lazy(() => import('./containers/Settings/Category/Category'));
const Subcategory = lazy(() => import('./containers/Settings/Subcategory/Subcategory'));
const CategoryForm = lazy(() => import('./containers/Settings/CategoryForm/CategoryForm'));

// Tools
const Popup = lazy(() => import('./containers/Tools/Popup/Popup'));

// Profile
const MailBox = lazy(() => import('./containers/Profile/Mailbox/Mailbox'));
const AccountProfile = lazy(() => import('./containers/Profile/Profile/Profile'));
const SettingsPersonal = lazy(() => import('./containers/Profile/Settings/Personal'));
const SettingsAgenda = lazy(() => import('./containers/Profile/Settings/Agenda'));
const SettingsLinks = lazy(() => import('./containers/Profile/Settings/Links'));
const SettingsContact = lazy(() => import('./containers/Profile/Settings/Contact'));
const SettingGroupSort = lazy(() => import('./containers/Profile/Settings/GroupSort'));
const SettingGroup = lazy(() => import('./containers/Profile/Settings/Group'));
const SettingShop = lazy(() => import('./containers/Profile/Settings/Shop'));
const SettingLocation = lazy(() => import('./containers/Profile/Settings/Location'));
const SettingShopWorkload = lazy(() => import('./containers/Settings/Shop/Workload/Workload'));
const SettingShopWorkloadPlanner = lazy(() => import('./containers/Settings/Shop/WorkloadPlanner/WorkloadPlanner'));

// Communication
const Mail = lazy(() => import('./containers/Communication/Mail/Mail'));
const Sms = lazy(() => import('./containers/Communication/Sms/Sms'));

// Authentication
const Login = lazy(() => import('./containers/Pages/Login'));
const ForgotPassword = lazy(() => import('./containers/Pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./containers/Pages/ResetPassword'));

const NotFound = lazy(() => import('./containers/Pages/NotFound'));
const ErrorPage = lazy(() => import('./containers/Pages/Error500'));

const Print = lazy(() => import('./containers/Pages/Print'));
const AutoRequest = lazy(() => import('./containers/Pages/AutoRequest'));

// SHOP MODULE
const ShopAllergen = lazy(() => import('./containers/Shop/Allergen/Allergen'));
const ShopCombiDeal = lazy(() => import('./containers/Shop/CombiDeal/CombiDeal'));
const ShopProductCategory = lazy(() => import('./containers/Shop/Product/Category/Category'));
const ShopProduct = lazy(() => import('./containers/Shop/Product/Product'));
const ShopProductOption = lazy(() => import('./containers/Shop/Product/Option/Option'));
const ShopProductOptionGroup = lazy(() => import('./containers/Shop/Product/Option/Group/OptionGroup'));
const ShopOrder = lazy(() => import('./containers/Shop/Order/Order'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    /* See full project for reference */
    routes.auth.login,
    routes.auth.forgotpassword,
    routes.auth.resetpassword,
    //routes.auth.resetpasswordcode
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    const animationName = ''; // 'rag-fadeIn'

    let isPage = false;
    listofPages.forEach(path => {
        if (location.pathname.indexOf(path) > -1) {
            isPage = true;
        }
    });

    if (
        // listofPages.indexOf(location.pathname) > -1 ||
        isPage ||
        location.pathname.includes('/print/') === true ||
        location.pathname.includes('/e/') === true
    ) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path={routes.e.al} component={waitFor(AutoRequest)} />

                        {/* See full project for reference */}
                        <Route path={routes.auth.resetpasswordcode} component={waitFor(ResetPassword)} />
                        <Route path={routes.auth.resetpassword} component={waitFor(ResetPassword)} />
                        <Route path={routes.auth.forgotpassword} component={waitFor(ForgotPassword)} />
                        <Route path={routes.auth.login} component={waitFor(Login)} />
                        <PrivateRoute path={routes.agenda.appointments.print} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.agenda.printWeek} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.agenda.printDay} component={waitFor(Print)} />
                        <PrivateRoute path={routes.agenda.agenda.groupPrint} component={waitFor(Print)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else {
        return (
            // Layout component wrapper
            <Base>
                <ErrorBoundary>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <PrivateRoute exact path={routes.home} component={waitFor(Dashboard)} />
                                    <PrivateRoute exact path={routes.dashboard} component={waitFor(Dashboard)} />

                                    <PrivateRoute path={routes.agenda.agenda.appointmentCopy} exact component={waitFor(MyAgenda)} />
                                    <PrivateRoute path={routes.agenda.agenda.appointmentMove} exact component={waitFor(MyAgenda)} />
                                    <PrivateRoute path={routes.agenda.myagenda} component={waitFor(MyAgenda)} />

                                    <PrivateRoute path={routes.agenda.agenda.appointmentMoveAgenda} exact component={waitFor(GroupOverview)} />
                                    <PrivateRoute path={routes.agenda.group} component={waitFor(GroupOverview)} />

                                    <PrivateRoute path={routes.agenda.agenda.searchfreeslot} component={waitFor(SearchFreeSlot)} />
                                    <PrivateRoute exact path={routes.agenda.agenda.history} component={waitFor(History)} />
                                    <PrivateRoute path={routes.agenda.agenda.conflicts} component={waitFor(Conflict)} />

                                    <PrivateRoute path={routes.agenda.appointments.overview} component={waitFor(Appointement)} />

                                    <PrivateRoute path={routes.contacts.overview} component={waitFor(Contact)} />

                                    <PrivateRoute path={routes.agenda.holidays.overview} component={waitFor(Holiday)} />
                                    <PrivateRoute path={routes.shop.settings.holidays.overview} component={waitFor(Holiday)} />

                                    <PrivateRoute path={routes.agenda.dayappointments.overview} component={waitFor(DayAppointment)} />

                                    <PrivateRoute path={routes.agenda.profiles.overview} component={waitFor(Profile)} />

                                    <PrivateRoute path={routes.agenda.appointmentstatuses.overview} component={waitFor(AppointmentStatus)} />

                                    <PrivateRoute path={routes.agenda.exceptions.overview} component={waitFor(Exception)} />

                                    <PrivateRoute path={routes.agenda.blockings.overview} component={waitFor(Blocking)} />

                                    <PrivateRoute path={routes.agenda.categories.overview} component={waitFor(Category)} />

                                    <PrivateRoute path={routes.agenda.subcategories.overview} component={waitFor(Subcategory)} />

                                    <PrivateRoute path={routes.agenda.categoryforms.overview} component={waitFor(CategoryForm)} />

                                    <PrivateRoute path={routes.agenda.popups.overview} component={waitFor(Popup)} />

                                    <PrivateRoute path={routes.agenda.mailbox.inbox} component={waitFor(MailBox)} />

                                    <PrivateRoute path={routes.discounts.overview} component={waitFor(Discount)} />
                                    <PrivateRoute path={routes.giftvouchers.overview} component={waitFor(GiftVoucher)} />

                                    <PrivateRoute path={routes.agenda.settings.account.profile} component={waitFor(AccountProfile)} />
                                    <PrivateRoute path={routes.agenda.settings.personal} component={waitFor(SettingsPersonal)} />
                                    <PrivateRoute path={routes.agenda.settings.agenda} component={waitFor(SettingsAgenda)} />
                                    <PrivateRoute path={routes.agenda.settings.links} component={waitFor(SettingsLinks)} />
                                    <PrivateRoute path={routes.agenda.settings.group.contact} component={waitFor(SettingsContact)} />
                                    <PrivateRoute path={routes.agenda.settings.group.sort} component={waitFor(SettingGroupSort)} />
                                    <PrivateRoute path={routes.agenda.settings.group.agenda} component={waitFor(SettingGroup)} />
                                    <PrivateRoute path={routes.shop.settings.group} component={waitFor(SettingShop)} />
                                    <PrivateRoute path={routes.shop.settings.location} component={waitFor(SettingLocation)} />
                                    <PrivateRoute path={routes.shop.settings.links} component={waitFor(SettingsLinks)} />
                                    <PrivateRoute path={routes.shop.settings.workload.overview} component={waitFor(SettingShopWorkload)} />
                                    <PrivateRoute path={routes.shop.settings.workloadplanner.overview} component={waitFor(SettingShopWorkloadPlanner)} />

                                    <PrivateRoute path={routes.agenda.communication.mail.compose} component={waitFor(Mail)} />
                                    <PrivateRoute path={routes.agenda.communication.sms.compose} component={waitFor(Sms)} />

                                    <PrivateRoute path={routes.shop.allergens.overview} component={waitFor(ShopAllergen)} />
                                    <PrivateRoute path={routes.shop.products.categories.overview} component={waitFor(ShopProductCategory)} />
                                    <PrivateRoute path={routes.shop.products.groups.options.overview} component={waitFor(ShopProductOption)} />
                                    <PrivateRoute path={routes.shop.products.groups.overview} component={waitFor(ShopProductOptionGroup)} />
                                    <PrivateRoute path={routes.shop.products.overview} component={waitFor(ShopProduct)} />
                                    <PrivateRoute path={routes.shop.orders.overview} component={waitFor(ShopOrder)} />
                                    <PrivateRoute path={routes.shop.combideals.overview} component={waitFor(ShopCombiDeal)} />

                                    <PrivateRoute path={routes.error} component={waitFor(ErrorPage)} />
                                    <PrivateRoute path={routes.notfound} component={waitFor(NotFound)} />

                                    <Redirect to={routes.notfound} />
                                </Switch>
                            </Suspense>
                        </CSSTransition>
                    </TransitionGroup>
                </ErrorBoundary>
            </Base>
        )
    }
};

export default withRouter(Routes);
