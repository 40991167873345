import agenda from './agenda/agenda.middleware';
import client from './client/client.middleware';
import group from './group/group.middleware';
import location from './location/location.middleware';
import notifications from './notifications/notifications.middleware';
import schemaData from './schema-data/schema-data.middleware';
import settings from './settings/settings.middleware';

export default [
    agenda,
    client,
    group,
    location,
    notifications,
    schemaData,
    settings
]