import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const ButtonIcon = props => (
    <Button color={props.color} tag={props.tag ? props.tag : Link} to={props.to} className={'btn-labeled ' + props.className}>
        <span className="btn-label"><i className={'fa fa-' + props.icon} aria-hidden></i></span>
        {props.children}
    </Button>
)

export default ButtonIcon;
