import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const ButtonActionOverview = props => (
    <React.Fragment>
        <Button color="default" size="sm" id={'buttonOverview' + props.i} tag={Link} to={props.to}>
            <i className="fas fa-list" aria-hidden></i>
        </Button>
        <UncontrolledTooltip placement="top" target={'buttonOverview' + props.i}>
            {props.children ? props.children : t('common:Overview')}
        </UncontrolledTooltip>
    </React.Fragment>
)

export default ButtonActionOverview;
