import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import routes from '../routing/routes';
import { connect } from 'react-redux';
import { UtilUrl } from '../utils';

const PrivateRoute = ({ component: Component, ...rest }) => {

    if (!rest.client) {
        return <Route {...rest} render={props => <Redirect to={{ pathname: routes.auth.login, state: { from: props.location } }} />} />
    } else if (
        (rest.client && UtilUrl.isModuleActive(rest.location.pathname)) ||
        rest.location.pathname === routes.dashboard
    ) {
        return <Route {...rest} render={props => <Component {...props} />} />
    } else {
        return <Route {...rest} render={props => <Redirect to={{ pathname: routes.dashboard }} />} />
    }

    /* return (
         <Route
             {...rest}
 
             render={props =>
                 rest.client ?
                     <Component {...props} /> :
                     <Redirect to={{ pathname: routes.auth.login, state: { from: props.location } }} />
             }
 
         render={props =>
             !rest.client ?
                 <Redirect to={{ pathname: routes.auth.login, state: { from: props.location } }} /> :
                 (
                     UtilUrl.isModuleActive(rest.location.pathname) ?
                         <Component {...props} /> :
                         <Redirect to={{ pathname: routes.dashboard }} />
                 )
         }
         />
     )*/
};

const mapStateToProps = state => ({ client: state.client ? state.client.active : null });

export default connect(mapStateToProps)(PrivateRoute);