import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { t } from 'i18next';

const ButtonPrint = props => (
    <Button color="default" tag={props.tag ? props.tag : Link} to={props.to} className={'btn-labeled ' + props.className} target="_blank">
        <span className="btn-label"><i className={'fa fa-' + (props.icon ? props.icon : 'print')} aria-hidden></i></span>
        {props.children ? props.children : t('common:Print')}
    </Button>
)

export default ButtonPrint;