import { API } from '../../services/api';
import { AGENDA_SET, AGENDA_UPDATE } from './agenda.actions';
import i18n from '../../i18n';

/*
   Hook into setting changes in order to change layout.
*/
const agenda = store => next => action => {
    let result = next(action)

    if (action.type === AGENDA_SET) {
        API.setAgenda(action.value.id);
    }

    if (action.name === 'logged_in' && (action.type === AGENDA_SET || action.type === AGENDA_UPDATE)) {
        i18n.changeLanguage(action.value.nativeLanguage);
    }

    return result
}

export default agenda;