import React from 'react';
import ReactDOM from 'react-dom';
import { ApiContactService } from '../services/api/contact.service';
import AutoSuggestItem from '../components/form/AutoSuggestItem';

const bindInputListener = (fields, contactDataFields, groupId) => {
    Object.entries(fields).forEach(([fieldId, field]) => {
        const fieldEl = document.getElementById(fieldId);

        if (fieldEl) {
            fieldEl.addEventListener('keyup', (e) => handleInput(e, fields, contactDataFields, groupId));
            fieldEl.addEventListener('click', () => resetAutoSuggest());
        }
    });
};

const handleInput = (e, fields, contactDataFields, groupId) => {
    let data = {
        'groupId': groupId
    };
    let reset = true;

    if (e.target.value.length < 2) {
        resetAutoSuggest();
        return;
    }

    Object.keys(fields).forEach((key) => {
        const value = document.getElementById(key).value;

        if (value) {
            reset = false;
        }

        data[fields[key]] = document.getElementById(key).value;
    });

    if (reset) {
        resetAutoSuggest();
        return;
    }

    ApiContactService.search(data)
        .then(
            results => {
                let autoSuggestEl = document.getElementById('autosuggest-' + e.target.id);
                ReactDOM.render(<AutoSuggestItem items={results} fields={contactDataFields} />, autoSuggestEl);

                let rdtPickerEls = autoSuggestEl.parentElement.getElementsByClassName('rdtPicker');
                [].forEach.call(rdtPickerEls, (el) => {
                    el.classList.add('hidden');
                });
            }
        );
};

const resetAutoSuggest = () => {
    [...document.getElementsByClassName('form-autosuggest')].forEach((el) => {
        ReactDOM.render(<AutoSuggestItem items={{}} fields={{}} />, el);

        let rdtPickerEls = el.parentElement.getElementsByClassName('rdtPicker');
        [].forEach.call(rdtPickerEls, (el) => {
            el.classList.remove('hidden');
        });
    });
};

export const UtilAppointment = {
    bindInputListener
};