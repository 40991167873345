import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { withLastLocation } from 'react-router-last-location';

class ButtonBack extends React.Component {
    render() {
        const { tag, lastLocation, to, className, children, forcePath } = this.props;

        let path = to;
        if (!forcePath && lastLocation) {
            path = lastLocation.pathname;
        }

        return (
            <Button color="default" tag={tag ? tag : Link} to={path} className={'btn-labeled ' + className}>
                <span className="btn-label"><i className="fa fa-arrow-left" aria-hidden></i></span>
                {children ? children : t('common:back.to.overview')}
            </Button>
        );
    }
}

export default withLastLocation(ButtonBack);
